import { ChangeDetectionStrategy, Component, Input } from '@angular/core';
import { IMedal } from './medals';
import { MatTooltip } from '@angular/material/tooltip';
import { TranslateModule } from '@ngx-translate/core';

@Component({
    selector: 'app-medal-img',
    templateUrl: './medal-img.component.html',
    styleUrls: ['./medal-img.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush,
    imports: [MatTooltip, TranslateModule],
})
export class MedalImgComponent {
    public src = '';
    public srcSet: string = null;

    public name = '';
    public width: number = null;

    @Input() set medal(value: IMedal) {
        if (value) {
            this.src = `/assets/img/medals/100/${value.code}.png`;
            this.srcSet = `assets/img/medals/30/${value.code}.png 576w, assets/img/medals/100/${value.code}.png 768w, assets/img/medals/150/${value.code}.png 992w`;
            this.name = value.name;
            this.width = value.width;
        }
    }
    @Input() position: number;
}
